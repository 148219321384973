// $Id$
Lyte.Component.register("crux-form-component", {
_template:"<template tag-name=\"crux-form-component\"> <lyte-yield yield-name=\"cruxFormComponent\" class=\"cruxFormComponent\"></lyte-yield> </template>",
_dynamicNodes : [{"type":"insertYield","position":[1]}],
_observedAttributes :["widthData"], //no i18n
	data : function(){
		return {
			widthData : Lyte.attr('array',{default:[]})//no i18n
		}
	},
	didConnect : function(){
		this.updateFormLabelWidth();
	},
	createSpanElement: function(){
		var spanElm = $L("<span id='dummyLabelSpan' class='dummyLyteLabelSpan f15 vH'>");
		$L("body").append(spanElm[0]); //eslint-disable-line zstandard/no-body-events
		return spanElm;
	},
	removeSpanElement: function(spanElm){
		spanElm[0].remove();
	},
	updateFormLabelWidth: function(){
		var currentForm = this.$node;
		var hasParentElement = currentForm.closest("crux-parent-form-component") ? true : false;//no i18n
		
		if(!hasParentElement){
			var currentFormRows = $L(currentForm).find(".crmFormComponentRow, .cruxFormComponentRow");
			this.setData({'widthData':[]}); //no i18n
			var widthData = this.getData('widthData'); //no i18n
			
			var spanElm = this.createSpanElement();
			if(widthData.length === 0){
				currentFormRows.each(function(i, elm){
					var lyteLabel = $L(elm).find(".lyteLabel, .cxElementLabel");
					if(lyteLabel.length === 0){
						return;
					}
					var cxInfoIconWt = 0;
					if(lyteLabel.find(".cxInfoIcon").length > 0){
						cxInfoIconWt = 20;
					}
					var lyteLabelText = lyteLabel.html();
					spanElm.html(lyteLabelText);
					var textWidth = Math.ceil(spanElm.outerWidth()) + 1 + cxInfoIconWt; //eslint-disable-line webperf/layout-thrashing
					widthData[widthData.length] = textWidth;
					this.setData('widthData',widthData);	//no i18n
				});
			}
			if(widthData.length > 0){
				var maxWidth = Math.max.apply(null, widthData);
				if(maxWidth > 200){
					maxWidth = 200;
				}
				$(currentFormRows).find(">.lyteLabel,>.cxElementLabel").css({"width": maxWidth + "px"});	//eslint-disable-line zstandard/no-jQuery-casting
				var currentFormWidth = maxWidth + 380;	//375 + 5
				$L(currentForm).css({minWidth: currentFormWidth+"px", display: 'block'});	//no i18n
			}
			this.removeSpanElement(spanElm);
		}
	}
});
